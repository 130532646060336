import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './QR.css';
import '../ImpactApi/ImpactApi.css';
import Loading from '../../Components/Loading/Loading';
import {AiOutlineInfoCircle} from 'react-icons/ai'

function QR() {
  const [data, setData] = useState({ url: '', width: '' });
  const [responsekey, setResponsekey] = useState('');
  const [imgData, setImgData] = useState('');
  const [Spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [widthError, setWidthError] = useState('');
  const [showInfoList, setShowInfoList] = useState(false);

  const toggleInfoList = () => {
    setShowInfoList(!showInfoList);
  };


  const valueUpdater = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    const { url, width } = data;
    if (url === '' || width === '') {
      toast.info('Please fill all fields');
    } else if (parseInt(width, 10) < 100) {
      setWidthError('The width must be 100 or greater than 100');
    } else {
      setLoading(true);
      setWidthError(''); // Clear any previous width error
      const response = await axios.post('https://backend.fori.co/qr/generateQR', data);
      const responseData = response.data;
      const key = responseData.key;
      setResponsekey(key);
    }
  };

  const getImage = async () => {
    try {
      console.log('inside');
      const response = await axios.get(`https://backend.fori.co/qr/getQRkey/${responsekey}`, { responseType: 'arraybuffer' });
      const buffer = new Uint8Array(response.data);
      console.log(response.data);
      const binary = buffer.reduce((data, byte) => data + String.fromCharCode(byte), '');
      const base64 = btoa(binary);
      const imageData = `data:image/png;base64,${base64}`;
      setImgData(imageData);
      setLoading(false);
    } catch (error) {
      console.error('Error retrieving image:', error);
    }
  };

  const linkRef = useRef(null); // Create a ref for the anchor tag

  useEffect(() => {
    getImage();
  }, [responsekey, Spinner]);

  return (
    <section className="qr">
      <form className="form card_layout card_QR">
        <div className="TextBox card_layout">
          <h1>Create your QR Code!</h1>
        </div>

        <div className="Selection_div">
          <div className="kmsshared">
            <h4 className="QRh4">Enter your url</h4>
            <input className="QRurl" placeholder="https://fori.co" type="text" id="url" name="url" value={data.url} onChange={valueUpdater} />
          </div>
          <div className="kmsshared">
          <h4 className="QRh4">
          Enter required width
          <AiOutlineInfoCircle type="button" className="info-button" onClick={(e) => { e.preventDefault(); toggleInfoList(); }}/>          
        </h4>
        {showInfoList ? 
            <div className='infoTab'>

              <ul className='ulInfoTab'>
              <li>The width is in pixels.</li>
              <li>Minimum width is 100 pixels.</li>
              <li>Width will be equal to height (square image)</li>
            </ul>
            </div>
            : ''
      
        }
            <input placeholder="100" type="number" min="100" id="width" name="width" value={data.width} onChange={valueUpdater} />
          </div>
        </div>
        {widthError && <p className="error_message">{widthError}</p>} {/* Display width error message */}
        <button type="submit" className="btn btn_primary calculate_btn" onClick={submit}>
          Create
        </button>
      </form>

      <div className="downloadDiv">
        {loading ? (
          <Loading /> // Render the loader when loading is true
        ) : imgData ? (
          <div>
            <div>
              <img src={imgData} alt="QR Code" />
            </div>
            <a ref={linkRef} href={imgData} download="qrcode.png" style={{ display: 'none' }}></a>
            <div className='qrBTn'> 
            <button className="btn btn_primary calculate_btn " onClick={() => linkRef.current.click()}>
              Download
            </button>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default QR;
