import React from 'react'
import './Form.css'
import {BiSend} from 'react-icons/bi'
import { useRef, useState, useEffect} from 'react';
import emailjs from 'emailjs-com';
import {toast} from "react-toastify";




const Form = () => {

  const form = useRef()
  const [input, setInput] = useState({
    name:'',
    email:'',
    message:''
  })



    const fillDetails=(e)=>{
          const inputName=e.target.name;
          const inputValue=e.target.value;
          setInput({...input,[inputName]:inputValue})
      }



      const sendEmail = (e) => {
        e.preventDefault();
        if(input.name!=='' && input.email!=='' && input.message!==''){
        emailjs.sendForm('service_oq9ubr8', 'template_8pxgwx8', form.current, 'NKZ6rJ2TPd2TYecM3') //fori
          .then((result)=>{
            toast.info(result.status===200 &&'Message Sent')
            setInput({
                name:'',
                email:'',
                message:''
              })
          })
          .catch((error)=>{
            toast.info(error.status===0 &&'Network Issue')
          })}
          else{
            toast.error('Please fill the required fields')
          }
      };
    





  return (
    <div className='Form_Container'>
        <h2 className="heading">Send Message</h2>
        <form action="" ref={form}>
        <input name="name" type="text" placeholder='Enter your name' value={input.name} onChange={fillDetails} required />
        <input type="email" name='email' placeholder='Enter your email' value={input.email} onChange={fillDetails} required />
        <textarea name="message" type="text"  placeholder='Type your message' rows="3" value={input.message} onChange={fillDetails} required />
        <div>
        <button type='submit' className='btn btn_primary contact_btn'  onClick={sendEmail}><BiSend style={{ fontSize: '1.5rem'}}/></button>
        </div>
        </form>
    </div>

  )
}

export default Form