import React from 'react'
import './ForiShare.css'
import bgvideo from '../../assets/car.webp'
  
const ForiShare = () => {
  return (
    <div className='ForiShare'>
        <div className="ForiShare_left">
            <h2 className='heading bold_heading'>What is FORI Share!</h2>
            <p className='para'>FORI's multimodal mobility sharing technology contributes to more than just asset ownership, ultimately leading to a greener environment</p>
        </div>
        <div className="ForiShare_right">
          <img src={bgvideo} alt="" />
        </div>
    </div>
  )
}

export default ForiShare