import React from 'react'
import './Map.css'

const Map = () => {
  return (
    <div className='map'>
    <h2 className='heading' style={{marginBottom:'1.5rem'}}>Office Locations</h2>
      <div className='map_child'><div className='mark'></div><p className="para">Oslo</p></div>
      <div className='map_child'><div className='mark'></div><p className="para">Dubai</p></div>
      <div className='map_child'><div className='mark'></div><p className="para">Washington, D.C</p></div>
      <div className='map_child'><div className='mark'></div><p className="para">Silicon Valley</p></div>
      <div className='map_child'><div className='mark'></div><p className="para">Islamabad</p></div>
    </div>

  )
}

export default Map