export const data=[
  {
    id:1,
    name: 'Impact APi',
    description: "Fori has developed an API to calculate your daily ride impact efficiently.",
    img: require('../assets/01.webp'),
    link:"/impact-Calculation"
},
  {
      id:2,
      name: 'Behavioural Dashboards',
      description: "We provide dashboards to analyse the usage & behavioural analytics",
      img: require('../assets/02.webp'),
      link:"https://dashboardv1.fori.co/"
  },
  {
      id:3,
      name: 'Analytical Dashboards',
      description: "We are helping our clients to explore their data for better business decisions",
      img: require('../assets/03.webp'),
      link:"https://dashboardv2.fori.co/"
  },
  {
      id:4,
      name: 'Impact Dashboards',
      description: "We provides efficient solutions to measure environmental impact",
      img: require('../assets/04.webp'),
      link:"https://app.powerbi.com/view?r=eyJrIjoiMTZkMzM1NmYtMGIyYS00MTBhLTkxYWYtMTExNjg2ZTFkYzQ3IiwidCI6IjVkNmNhMzU1LTJjNzQtNGI3Yy1hOTI2LTBlMGUxYjg0MjUxNiIsImMiOjl9"
  },
  {
    id:5,
    name: 'QR Generator',
    description: "We provide a QR code generation service that enables you to create QR codes for quick access to information or specific actions",
    img: require('../assets/5.png'),
    link:"/qrCode-generator"
  },

]

export const ServicesData=[
  {id:1,
  h:"Backend Development",
  p:" FORI creates systems and web applications where 'behind-the-scenes' functionality is always time and cost-efficient.",
  img:"backendDevelopment.jpg"
  },

  {id:2,
  h:"Mobility Solutions",
  p:"Business mobility solutions will help you rapidly grow your business on a lesser cost of environmental damages",
  img:"mobilitysolution.jpg"

  },
  {id:3,
  h:"Bluetooth Integrations",
  p:"Fori enables Bluetooth integration, including network stacks, which allows a device to exchange data wirelessly",
  img:"bluetooth.jpg"

},
  {id:4,
  h:"Impact Dashboards",
  p:"Fori is leading in the path of business analytics to provide one stop shop solutions for informed decisions",
  img:"Co2.jpg"
},

]

export const partners=[
  {
      id: 1,
      logo: "azure.png"
    },
    {
      id: 2,
      logo: "hayk.png"
    },
    {
      id: 3,
      logo: "sapNs2.png"
    },
    {
      id: 4,
      logo: "p.png"
    },
    {
      id: 5,
      logo: "telenor.png"
    },
    {
      id: 6,
      logo: "forimazoodri.png"
    },
    {
      id: 7,
      logo: "undp.png"
    },
    {
      id: 8,
      logo: "velocity.png"
    },
    {
      id: 9,
      logo: "aws.png"
    },
    {
      id: 10,
      logo: "jazz.png"
    },
  ];

