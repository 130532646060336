import React from 'react'
import './ScrollProgress.css'
import { useState, useEffect } from 'react'
import {BsArrowUp} from 'react-icons/bs'

const ScrollProgress = ({bgColor, gradientColor}) => {
    const [completion, setCompletion] = useState(0)

    useEffect(()=>{
        const updateScrollCompletion =() =>{
            const currentProgress = window.scrollY
            const scrollHeight = document.body.scrollHeight - window.innerHeight
            // console.log(currentProgress ,  scrollHeight)
            if(scrollHeight){
                setCompletion(Number(currentProgress/scrollHeight).toFixed(2)*100)
            }
        }
        
        window.addEventListener('scroll', updateScrollCompletion)

        return()=>{
            window.removeEventListener('scroll', updateScrollCompletion)

        }

    },[])


  return (
    <div className={completion>5 ? 'progress' : 'onHidePRogress'} style={{background: `conic-gradient( ${gradientColor} ${completion}%, transparent ${completion}%)`}}>
    <span className={completion>5 ? 'ProgressValue' : 'onHideValue'} style={{backgroundColor:bgColor}} onClick={()=>{document.documentElement.scrollTop=0}}><BsArrowUp style={{color:gradientColor}}/></span>

    
    </div>
  )
}

export default ScrollProgress