import React, { useState } from 'react';
import './ProductsSection.css'
import {CgArrowRightO,CgArrowLeftO} from "react-icons/cg";
import { data } from '../../data/AppData'

//  Functions to change product


const ProductsSection= () => {

  const [id, setId] = useState(1)

  const nextSlide = ()=>{
    if(id<data.length){
      setId(id+1)
    }

  }
  const previousSlide = ()=>{
    if(id>1){
      setId(id-1)
    }
  }

  const project = data.find((item)=>{
    return item.id ===id
  })

  return (
    <section id='products' className='products_section'>
        <div className='headerProducts'>
          <h2 className='heading bold_heading'>Products & Smart Solutions</h2>
          <p className='para'>Our team has developed products to aid informed decision making which helps you to grow exponentially</p>
        </div>
      <div className='Products_banner'>
        <div className='product_image'>
        {data.map((item)=>{
          return(
              <img key={item.id} className={item.id===id?'CurrentSlide':'HideSlide'} src={item.img} alt="" />
          )
        })}
            </div>

        <div className='Products_description'>
          <h2 className='heading'>{project.name}</h2>
          <p className='para paraheight'>{project.description}</p>
          <div className='button_container'>
          <div className='navigationButton'>
          <button className={id<=1?'deactive':'active'} onClick={previousSlide}><CgArrowLeftO className='Arrow_icon'/></button>
          <a href={project.link} className='viewDemo' target='_blank'  rel="noreferrer">ViewDemo</a>
          <button className={id>=data.length?'deactive':'active'} onClick={nextSlide}><CgArrowRightO className='Arrow_icon'/></button>
          </div>
        </div>
        </div>
        </div>
        
</section>
  )
}

export default ProductsSection