import React, {Suspense, lazy}from 'react';
import './App.css';
import Home from './Pages/Home/Home';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import QR from './Pages/QR/QR';
const LazyImpactApi = lazy(()=> import('./Pages/ImpactApi/ImpactApi'))

const App = () => {
  return (
    <Router>
    <div className='app'>
      <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/impact-Calculation" element={<Suspense fallback={<div></div>}><LazyImpactApi/></Suspense>}/>
      <Route path="/qrCode-generator" element={<QR/>}></Route>
      </Routes>
      <ToastContainer/>
    </div>

    </Router>
  )

}

export default App
