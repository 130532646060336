import './Home.css';
import About from '../../Components/About/About';
import ForiBanner from '../../Components/ForiBanner/ForiBanner';
import Mission from '../../Components/Mission/Mission';
import API from '../../Components/API/API'
import Services from '../../Components/services/services'
import ImageComp from '../../Components/ImageComp/ImageComp';
import OurPartners from '../../Components/OurParteners/OurPartners'
import Partners from '../../Components/Partners/Partners'
import MobileNav from '../../Components/MobileNav/MobileNav'
import ContactUs from '../../Components/Contact_Us/ContactUs';
import ForiShare from '../../Components/ForiShare/ForiShare';
import ProductsSection from '../../Components/ProductsSection/ProductsSection';
import ScrollProgress from '../../Components/ScrollProgress/ScrollProgress'
function Home() {
  return (
    <div className="Home">
          <MobileNav/>
          <ForiBanner/>
          <About/>
          <Mission/>
          <ImageComp sectionName = 'one'/>
          <API/>
          <ProductsSection/>
          <ForiShare/>
          <Services/>
          <Partners/>
          <OurPartners/>
          <ContactUs/>
          <ScrollProgress bgColor="rgba(255,255,255,0.9)" gradientColor="#9c9c9c"/>
          
    </div>
  );
}

export default Home;
