import React from 'react'
import './Info.css'
import {BiPhone} from 'react-icons/bi'
import {BiMap} from 'react-icons/bi'
import {MdOutlineAlternateEmail} from 'react-icons/md'

const Info = () => {
  return (
    <div className='info_parent_container'>
        <h2 className="heading">Contact Details</h2>
        <div className="Info_container">
            <div className="Info_child">
                <BiPhone/>
                <a href="tel:+4740717345" className="para">+47 407 17 345</a>
            </div>
            <div className="Info_child">
                <MdOutlineAlternateEmail/>
                <a href='mailto:info@fori.co' className='para'>info@fori.co</a>
            </div>
        </div>
    </div>
  )
}

export default Info